.navbar-brand {
  padding: 0;
  margin-right: 0;
}

.brand {
  cursor: pointer;
}

.navbar-default .navbar-nav > li > span {
  color: #eee
}

.navbar-nav > li > span {
  display: inline;
  padding: 27px 7px 5px 6px;
}

.navbar-default .navbar-nav > li > span:hover {
  cursor: pointer;
  color: #dddddd;
  background-color: #242424;
}

.navbar {
  padding: 0 1rem 0;
}

.navbar-nav, .navbar-nav > a, .nav-item > a {
  color: #eee !important;
  margin: 5px 0 0 0;
}

.navbar-default .navbar-brand:focus,.navbar-default .navbar-brand:hover {
  color: #4d9d37;
  background-color: #4d9d37;
}

.logout-link {
  font-size: 12px !important;
  height: 17px;
}

.username {
  display: flex;
  justify-content: flex-end;
  padding-left: 15px;
  padding-right: 15px;
}

.speedDialToolTip {
  min-width: 230px;
  text-align: center;
}

.speedDialToolTipDisabled {
  color: #AAAAAA !important;
  background: #EFEFEF !important;
}
